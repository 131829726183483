









































































































































import { Component, Vue } from 'vue-property-decorator';
import { searchPlaces } from '@/helpers/mapbox';
import { getTemplates } from '@/helpers/api';
import { Place } from '@/types/mapbox-gl';
import { startSearch } from '@/helpers/fuse';
import { getGeometry } from '@/helpers/turf';
import analytics from '@/analytics';
import { fetchLanding } from '@/helpers/common';
import * as Sentry from '@sentry/vue';
import { updateSession } from '@/helpers/session';

@Component({
  components: {}
})
export default class Home extends Vue {
  private search = '';
  private searchOptionsHandler: Array<Place> = [];
  private searchedPOIS: any = [];
  private content = {
    title: this.$t('home.visible'),
    subtitle: this.$t('home.some'),
    body: this.$t('home.creating_visual'),
    image: '/temp/splash.jpg'
  };
  private async loadCities() {
    this.$store.commit('setTemplates', await getTemplates());
    //this.$store.commit('setPOIs', await getPOIs(this.$store.state.selectedCity));
    analytics.track('startSession', {
      city: this.$store.state.selectedCity?.place_name,
      goal: this.$store.state?.adType
    });
    await this.$router.push({ name: 'broadcast' });
  }

  private updateValue(data: Place) {
    if (!data) return;
    this.search = data.place_name;
    this.$store.commit('setSelectedCity', data);
    this.$store.commit('setPlace', data);
    updateSession({
      map: { search: this.search, params: data }
    });
  }

  private changeType(type: string) {
    this.$store.commit('setAdType', type);
    updateSession({ adType: type });
  }

  private async searchAddress(search: string) {
    this.$store.commit('setSelectedCity', null);
    if (search && search.length) {
      //@ts-ignore
      this.searchOptionsHandler = await searchPlaces({
        search,
        types: ['postcode', 'locality', 'place'],
        language: 'fr',
        country: ['be', 'fr']
      });
    }

    const temp = await startSearch(search);
    if (this.$store.state.config.hidePlaceSearch) this.searchOptionsHandler = [];
    this.searchedPOIS = getGeometry(temp);
    if (this.searchedPOIS.length) this.searchOptionsHandler.unshift(...this.searchedPOIS);
  }

  private async created() {
    if (this.$store.state.custom?.landing) this.content = this.$store.state.custom?.landing;

    updateSession({
      currentPage: this.$route.name
    });
    const query = this.$route.query;
    if (typeof query.source === 'string' && query.source && query.source.trim().length) {
      this.content = await fetchLanding(query.source.trim());
    } else if (process.env.VUE_APP_BUILD) {
      const temp = await fetchLanding(process.env.VUE_APP_BUILD);
      if (temp) this.content = temp;
    }
    if (this.$store.state.config.splashText) {
      this.content.body = this.$store.state.config.splashText;
    }
    if (this.$store.state.selectedCity) {
      this.search = this.$store.state.selectedCity.place_name;
    }
    if (this.$store.state.place) {
      this.updateValue(this.$store.state.place);
    }
  }
}
